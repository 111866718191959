/* App.css */

/* ヘッダー */
.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 997;
  transition: all 0.5s;
  padding: 20px 0;
  background: #fff;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.header .logo {
  display: flex;
  align-items: center;
}

.header .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.header .logo span {
  font-size: 30px;
  font-weight: 700;
  color: #012970;
  font-family: "Nunito", sans-serif;
}

.navbar {
  display: flex;
  align-items: center;
}

.navbar ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar li {
  margin-left: 30px;
}

.navbar a {
  color: #013289;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  transition: 0.3s;
}

.navbar a:hover {
  color: #4154f1;
}

.navbar .active {
  color: #4154f1;
}

/* ヒーローセクション */
.hero {
  display: flex;
  align-items: center;
  padding: 60px 0;
  background: #f5f8fd;
}

.hero h1 {
  font-size: 48px;
  font-weight: 700;
  color: #012970;
}

.hero h2 {
  font-size: 24px;
  color: #555;
  margin-top: 10px;
}

.btn-get-started {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
  background: #4154f1;
  color: #fff;
  border-radius: 4px;
  transition: 0.3s;
}

.btn-get-started:hover {
  background: #5969f3;
}

/* アバウトセクション */
.about {
  padding: 60px 0;
}

.about .content h3 {
  font-size: 24px;
  font-weight: 700;
  color: #012970;
}

.about .content h2 {
  font-size: 20px;
  color: #555;
  margin-top: 10px;
}

.about .content p {
  color: #777;
  margin-top: 20px;
}

/* プロダクトセクション */
.values {
  padding: 60px 0;
}

.values .section-header h2 {
  font-size: 32px;
  font-weight: 700;
  color: #012970;
}

.values .section-header p {
  color: #777;
  margin-top: 10px;
}

.values .box {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.values .box:hover {
  box-shadow: 0px 0px 40px rgba(127, 137, 161, 0.35);
}

.values .box h3 {
  font-size: 20px;
  font-weight: 700;
  color: #012970;
}

.values .box p {
  color: #777;
  margin-top: 10px;
}

/* フッター */
.footer {
  background: #012970;
  color: #fff;
  padding: 40px 0;
}

.footer .footer-top {
  padding-bottom: 30px;
}

.footer .footer-info {
  margin-bottom: 30px;
}

.footer .footer-info .logo {
  display: flex;
  align-items: center;
}

.footer .footer-info .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.footer .footer-info .logo span {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
}

.footer .footer-links h4 {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
}

.footer .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-links ul li {
  margin-bottom: 10px;
}

.footer .footer-links ul li a {
  color: #fff;
  transition: 0.3s;
}

.footer .footer-links ul li a:hover {
  color: #8894f6;
}

.footer .footer-contact p {
  color: #fff;
}

.back-to-top {
  position: fixed;
  right: 15px;
  bottom: 15px;
  background: #4154f1;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
  visibility: hidden;
  opacity: 0;
}

.back-to-top:hover {
  background: #6776f4;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}